import { is } from 'ramda';

export function findPathToKey(key: string, obj: Record<string, any>) {
  const findPathRecursively = (
    key: string | number,
    obj: Record<string, any>,
    path: (string | number)[] = [],
  ): (string | number)[] | null => {
    if (is(Object, obj)) {
      for (const [k, v] of Object.entries(obj)) {
        const parsedKey = isNaN(Number(k)) ? k : Number(k);
        if (k === key) {
          return [...path, parsedKey];
        }
        const result = findPathRecursively(key, v, [...path, parsedKey]);
        if (result) return result;
      }
    }

    return null;
  };

  return findPathRecursively(key, obj) ?? [];
}
