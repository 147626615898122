import { hasPath, insert, insertAll, lensPath, remove, set, update, view } from 'ramda';

import { IAccordionSpoiler, TBlock } from 'detailsv2/__blocks';
import { InsertAtPosition } from 'types/blocks';
import { findPathToKey } from 'utils';
import { isDefined, isNotDefined } from 'utils/ramda';

type AddBlockInsideAccordionProps = {
  data: {
    pages?: {
      blocks?: TBlock[];
    }[];
  };
  pageIndex: number;
  blockIndex: number;
  blockOrBlocks: TBlock | TBlock[];
  spoilerIndex: number;
  spoilerBlockIndex: number;
  insertPosition?: InsertAtPosition;
};

type EditBlockInsideAccordionProps = {
  newObject: TBlock;
} & Omit<AddBlockInsideAccordionProps, 'blockOrBlocks'>;

type RemoveBlockInsideAccordionProps = Omit<AddBlockInsideAccordionProps, 'blockOrBlocks'>;

export const addBlockInsideAccordionHandler = (
  props: AddBlockInsideAccordionProps,
): AddBlockInsideAccordionProps['data']['pages'] | undefined => {
  const {
    data,
    pageIndex,
    blockIndex,
    blockOrBlocks,
    spoilerIndex,
    spoilerBlockIndex,
    insertPosition = 'after',
  } = props;

  const block = data?.pages?.[pageIndex]?.blocks?.[blockIndex];
  if (isNotDefined(block)) return;
  if (!block) return; // ts check

  const spoilersPath = findPathToKey('spoilers', block);
  const spoilerPath = [...spoilersPath, spoilerIndex];

  const stringifyPath = spoilerPath.map((o) => o.toString());
  if (!hasPath(stringifyPath, block)) return;

  const spoilerLens = lensPath<TBlock, IAccordionSpoiler>(spoilerPath);
  const spoilerData = view(spoilerLens, block);
  const isSpoilerChildrenExist = isDefined(spoilerData?.children);

  let updatedSpoiler;

  if (!isSpoilerChildrenExist) {
    updatedSpoiler = {
      ...spoilerData,
      children: Array.isArray(blockOrBlocks) ? blockOrBlocks : [blockOrBlocks],
    };
  } else {
    const insertShift = insertPosition === 'before' ? 0 : 1;
    const updatedSpoilerChildren = Array.isArray(blockOrBlocks)
      ? insertAll(spoilerBlockIndex + insertShift, blockOrBlocks, spoilerData?.children!)
      : insert(spoilerBlockIndex + insertShift, blockOrBlocks, spoilerData?.children!);

    updatedSpoiler = {
      ...spoilerData,
      children: updatedSpoilerChildren,
    };
  }

  const updatedBlock = set(spoilerLens, updatedSpoiler, block);
  const blockLens = lensPath<AddBlockInsideAccordionProps['data']['pages'], TBlock>([pageIndex, 'blocks', blockIndex]);
  const updatedPages = set(blockLens, updatedBlock, data.pages);

  return updatedPages;
};

export const editBlockInsideAccordionHandler = (
  props: EditBlockInsideAccordionProps,
): EditBlockInsideAccordionProps['data']['pages'] | undefined => {
  const { data, pageIndex, blockIndex, spoilerIndex, spoilerBlockIndex, newObject } = props;

  const block = data?.pages?.[pageIndex]?.blocks?.[blockIndex];
  if (isNotDefined(block)) return;
  if (!block) return; // ts check

  const spoilerChildrenPath = [...findPathToKey('spoilers', block), spoilerIndex, 'children'];
  const stringifyPath = spoilerChildrenPath.map((o) => o.toString());
  if (!hasPath(stringifyPath, block)) return;

  const spoilerChildrenLens = lensPath<TBlock, IAccordionSpoiler['children']>(spoilerChildrenPath);
  const spoilerChildrenData = view(spoilerChildrenLens, block)!; // checked upper in hasPath
  const updatedSpoilerChildren = update(spoilerBlockIndex, newObject, spoilerChildrenData);

  const updatedBlock = set(spoilerChildrenLens, updatedSpoilerChildren, block);

  const blockLens = lensPath([pageIndex, 'blocks', blockIndex]);
  const updatedPages = set(blockLens, updatedBlock, data.pages);

  return updatedPages;
};

export const removeBlockInsideAccordionHandler = (
  props: RemoveBlockInsideAccordionProps,
): { updatedPages: RemoveBlockInsideAccordionProps['data']['pages'] | undefined; showAlert: boolean } | undefined => {
  const { data, pageIndex, blockIndex, spoilerIndex, spoilerBlockIndex } = props;

  const block = data?.pages?.[pageIndex]?.blocks?.[blockIndex];
  if (isNotDefined(block)) return;
  if (!block) return; // ts check

  const spoilersPath = findPathToKey('spoilers', block);
  const spoilerChildrenPath = [...spoilersPath, spoilerIndex, 'children'];
  const stringifyPath = spoilerChildrenPath.map((o) => o.toString());
  if (!hasPath(stringifyPath, block)) return;

  const spoilerChildrenLens = lensPath<TBlock, IAccordionSpoiler['children']>(spoilerChildrenPath);
  const spoilerChildrenData = view(spoilerChildrenLens, block)!; // checked upper in hasPath

  const deletedBlock = spoilerChildrenData[spoilerBlockIndex];
  const updatedSpoilerChildren = remove(spoilerBlockIndex, 1, spoilerChildrenData);

  const updatedBlock = set(spoilerChildrenLens, updatedSpoilerChildren, block);

  const blockLens = lensPath([pageIndex, 'blocks', blockIndex]);
  const updatedPages = set(blockLens, updatedBlock, data.pages);

  const showAlert =
    deletedBlock?.type === 'image' || deletedBlock?.type === 'embed' || deletedBlock?.type === 'document';

  return { updatedPages, showAlert };
};
